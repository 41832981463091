import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavTakeEvaluation from "./navTakeEvaluation";
import parseFileStructure from "./parseFileStructure";
import parseZipFile from "./parseZipFile";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import LocationsForExam from "./locationsForExam";
import PdfTakeEval from "./pdfTakeEval";
import CustomExamExtendedWrapperView from "./customExamExtendedWrapperView";
import SplitScreenView from "./splitScreenView";
import fetchWithPreSigned from "./fetchWithPreSigned";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Questions from "./questions";
import ShowError from "./showError";
import zipUpContents from "./zipUpContents";
import { Mutex } from 'async-mutex';
import redirectArray from "./redirectArray";

const TakeEvaluation = () => {
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [requestLifeCycleThree, setRequestLifeCycleThree] = useState(false);
    const [requestLifeCycleFour, setRequestLifeCycleFour] = useState(false);
    const [zipFileExists, setZipFileExists] = useState(false);
    const [pdfExists, setPdfExists] = useState(false)
    const [questions, setQuestions] = useState([]);
    const [contents, setContents] = useState(null);
    const [questionsToSave, setQuestionsToSave] = useState({});
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.CUSTOM_EXAM);
    const [pdf, setPdf] = useState("");
    const [contentsNeedToBeSaved, setContentsNeedToBeSaved] = useState({
        save: false
    });
    const [useSyntaxHighlighting, setUseSyntaxHighlighting] = useState(false);    
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [error, setError] = useState(null);
    const [attempt, setAttempt] = useState(null);
    const [status, setStatus] = useState(null);
    const [allowedAttemptsProgramming, setAllowedAttemptsProgramming] = useState(null);
    const [coupledProgrammingQuestions, setCoupledProgrammingQuestions] = useState([]);
    const [times, setTimes] = useState({
        startTime: "",
        endTime: ""
    })
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [flags, setFlags] = useState({});
    const [lastStartTime, setLastStartTime] = useState(null);
    const [currentViewerSplitScreen, setCurrentViewerSplitScreen] = useState(null);
    const [open, setOpen] = useState(true);
    const [flip, setFlip] = useState(false);
    const [scrollAmount, setScrollAmount] = useState(0);
    const [resetZipFile, setResetZipFile] = useState(false);
    const [autoSubmit, setAutoSubmit] = useState(false);
    const [tryingToReload, setTryingToReload] = useState(false);
    const [requestLifeCycleNav, setRequestLifeCycleNav] = useState(false);
    const [isAttemptingToSubmit, setIsAttemptingToSubmit] = useState(false);
    const [text, setText] = useState("Save");
    const [mutex, setMutex] = useState(new Mutex());
    const [submitMutex, setSubmitMutex] = useState(new Mutex());

    function updateText(newText) {
        setText(newText);
    }

    function updateIsAttemptingToSubmit(is) {
        setIsAttemptingToSubmit(is);
    }

    function updateAutoSubmit(newAuto) {
        setAutoSubmit(newAuto);
    }

    function updateTryingToReload(newAuto) {
        setTryingToReload(newAuto);
    }
    function updateRequestLifeCycleNav(newAuto) {
        setRequestLifeCycleNav(newAuto);
    }

    function updateScrollAmount(newAmount) {
        setScrollAmount(newAmount);
    }

    function updateFlip(newFlip) {
        setFlip(newFlip);
    }

    function updateQuestions(newQuestions) {
        setQuestions(newQuestions);
    }

    function updateOpen(newOpen) {
        setOpen(newOpen);
    }

    function updateCurrentViewerSplitScreen(newViewer) {
        setCurrentViewerSplitScreen(newViewer);
    }

    function updateComponentLastStartTime(newTime) {
        setLastStartTime(newTime);
    }

    function updateRequestLifeCycleFour(newBoolean) {
        setRequestLifeCycleFour(newBoolean);
    }

    function updateTestsConfigDict(newTestsConig) {
        setTestsConfigDict(newTestsConig);
    }
    
    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        submitForm(theme);
        setIsDarkTheme(theme);        
    }

    function updateFlags(newFlags) {
        setFlags(newFlags);
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;                
            }

        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const getCustomExamStudent = async () => {
            try {
                setRequestLifeCycle(true);
                const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL  + `/get-custom-exam-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    // set the data
                    updateQuestions(data.questionsInOrder);
                    setZipFileExists(data.zipFileExists);
                    setPdfExists(data.pdfExists);                    
                    setUseSyntaxHighlighting(data.useSyntaxHighlighting);
                    setAllowedAttemptsProgramming(data.allowedAttemptsProgramming);                    
                    setTimes({
                        finalTime: data.finalTime,
                        duration: data.duration
                    })
                    setResetZipFile(data.resetZipFile)
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else if (response.status in redirectArray) {
                    navigate(`/${courseCode}/${assessmentCode}`);
                }

            } catch (error) {
                console.log(error)
            } finally {
                setRequestLifeCycle(false);
            }
        }

        getCustomExamStudent();

    }, [])

    const fetchZip = async () => {
        try {
            setRequestLifeCycleThree(true);
            const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-submission-zip-student-evaluation/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            if (res.status === 200) {
                const blob = await res.blob(); // Get the ZIP as a blob
                const contents = parseFileStructure(await parseZipFile(blob));
                setContents(contents);
                setZipFileExists(true);
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else if (res.status in redirectArray) {
                navigate(`/${courseCode}/${assessmentCode}`);
            } else {
                setZipFileExists(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycleThree(false);
        }
    };

    useEffect(() => {
        if (zipFileExists) {
            fetchZip();  
        }

    }, [zipFileExists])

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setRequestLifeCycleTwo(true);   
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL  + `/get-assessment-pdf-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }

                    const pdfUrl = URL.createObjectURL(blob); // Directly create object URL
                    setPdf(pdfUrl)
                    setPdfExists(true);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else if (res.status in redirectArray) {
                    navigate(`/${courseCode}/${assessmentCode}`);
                } else {
                    setRequestLifeCycleTwo(false);
                }

            } catch (error) { 
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };

        if (pdfExists) {
            fetchPdf();
        }

        return () => {
            if (pdf) {
                URL.revokeObjectURL(pdf);
            }
        }

    }, [pdfExists])    

    function updateQuestionsToSave(questionsToSave) {
        setQuestionsToSave(questionsToSave);
    }

    function updateCurrentViewer(viewer) {
        setCurrentViewer(viewer);
    }

    function updateContentsNeedToBeSaved(bool) {
        setContentsNeedToBeSaved(bool);
    }

    function updateContents(contents, location) {
        updateContentsNeedToBeSaved((prev) => {
            return {
                save: true,
                locations: {
                    ...prev?.locations,
                    [location]: null
                }
            }
        });
        setContents(contents);
    }

    function updateContentsToNull() {
        setContents(null);
    }

    function updateAttempt(newAttempt) {
        setAttempt(newAttempt);
    }

    function updateStatus(newStatus) {
        setStatus(newStatus);
    }

    function updateError(newError) {
        setError(newError);
    }

    const saveAnswersCritical = async () => {
        const release = await mutex.acquire();
        
        try {
            updateText("Saving");
            if (!(questionsToSave && (Object.keys(questionsToSave).length > 0 || contentsNeedToBeSaved?.save))) {
                return;
            }
            
            // create formdata
            const formData = new FormData();
            formData.append('courseCode', courseCode);
            formData.append('assessmentCode', assessmentCode);
            formData.append('answers', JSON.stringify(Object.keys(questionsToSave).map(key => ({...questionsToSave[key], uuid: key}))));

            // check if we have a zip file
            if (contentsNeedToBeSaved?.save) {
                formData.append('contents', await zipUpContents(contents));
            }

            const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/temporary-save-answers"
            const urlOptions = {
                method: "POST",
                credentials: "include",
                body: formData
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();

            if (response.status === 200) {
                updateQuestionsToSave({});
                updateContentsNeedToBeSaved({
                    save: false
                });
                updateText("Save");
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }else if (response.status in redirectArray) {
                navigate(`/${courseCode}/${assessmentCode}`);
            } else {
                updateText("Failed");
                alert("Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch(error) {
            updateText("Failed");
            alert("Error: " + error.message);
            console.log(error);
            throw error;

        } finally {
            release();
        }
    };

    const viewerToReturn = () => {
        switch(currentViewer) {
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomExamExtendedWrapperView saveAnswersCritical={saveAnswersCritical} scrollAmount={scrollAmount} updateScrollAmount={updateScrollAmount} flip={flip} updateQuestions={updateQuestions} lastStartTime={lastStartTime} requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} requestLifeCycleTwo={requestLifeCycleThree} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents}/>                
            }
            case TypesOfLocationEnum.PDF: {
                return <PdfTakeEval currentViewer={TypesOfLocationEnum.PDF} requestLifeCycle={requestLifeCycleTwo} pdf={pdf}/>
            }
            case TypesOfLocationEnum.SPLIT_SCREEN: {
                return <SplitScreenView requestLifeCycleThree={requestLifeCycleThree} saveAnswersCritical={saveAnswersCritical} scrollAmount={scrollAmount} updateScrollAmount={updateScrollAmount} flip={flip} updateQuestions={updateQuestions} errorExists={error} pdfExists={pdfExists} updateCurrentViewer={updateCurrentViewer} updateCurrentViewerSplitScreen={updateCurrentViewerSplitScreen} currentViewerSplitScreen={currentViewerSplitScreen} lastStartTime={lastStartTime} requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents} requestLifeCycleTwo={requestLifeCycleTwo} pdf={pdf}/>
            }
            case TypesOfLocationEnum.ERROR: {
                return <ShowError error={error} />
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        const fetchTests = async () => {
            try {
                updateRequestLifeCycleFour(true);
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-tests-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    if (data.OK) {
                        const testsConfigArray = JSON.parse(data.testsConfig)
                        updateTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                            acc[curr.id] = curr;
                            return acc;
                        }, {}));
                        updateError(null);
                    } else {
                        updateError(data.error);
                        updateTestsConfigDict({});
                    }
                    setAttempt(data.attempt);
                    setStatus(data.status);
                    setLastStartTime(data.lastStartTime);
                } else if (response.status === 401) {                    
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else {
                    updateError(data.detail);
                    updateTestsConfigDict({});
                }
            } catch (error) {
                updateError(error.message);
                updateTestsConfigDict({});
                console.log(error);
            } finally {
                updateRequestLifeCycleFour(false);
            }
        };
        
        fetchTests();
    }, [])

    useEffect(() => {
        if (questions && questions.length > 0) {
            const programmingQuestionIndices = questions
                .map((question, index) => 
                    question.questionType === TypesOfQuestionsEnum.PROGRAMMING ? index + 1 : undefined
                )
                .filter(index => index !== undefined);
            
            setCoupledProgrammingQuestions(programmingQuestionIndices);
        }

    }, [questions]);

    useEffect(() => {
        if (error) {
            updateCurrentViewerSplitScreen(TypesOfLocationEnum.ERROR);
            updateCurrentViewer(TypesOfLocationEnum.SPLIT_SCREEN);
        } else {
            updateCurrentViewerSplitScreen(TypesOfLocationEnum.CUSTOM_EXAM);
            updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM)
        }

    }, [error])

    useEffect(() => {
        let timer = null;

        if (text !== "Saving" && text !== "Save") {
            timer = setTimeout(() => {
                updateText("Save");
            }, 2000)
        }

        return () => {
            clearTimeout(timer);
        }

    }, [text])
    
    return (
        <>
            <NavTakeEvaluation submitMutex={submitMutex} text={text} updateIsAttemptingToSubmit={updateIsAttemptingToSubmit} isAttemptingToSubmit={isAttemptingToSubmit} updateContentsToNull={updateContentsToNull} updateRequestLifeCycleNav={updateRequestLifeCycleNav} updateAutoSubmit={updateAutoSubmit} updateTryingToReload={updateTryingToReload} autoSubmit={autoSubmit} tryingToReload={tryingToReload} requestLifeCycleNav={requestLifeCycleNav} fetchZip={fetchZip} resetZipFile={resetZipFile} saveAnswersCritical={saveAnswersCritical} updateFlip={updateFlip} flip={flip} updateCurrentViewerSplitScreen={updateCurrentViewerSplitScreen} pdfExists={pdfExists} error={error} currentViewerSplitScreen={currentViewerSplitScreen} currentViewer={currentViewer} zipFileExists={zipFileExists} status={status} lastStartTime={lastStartTime} updateComponentLastStartTime={updateComponentLastStartTime} updateTestsConfigDict={updateTestsConfigDict} updateRequestLifeCycleFour={updateRequestLifeCycleFour} updateError={updateError} flags={flags} updateStatus={updateStatus} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} attempt={attempt} allowedAttemptsProgramming={allowedAttemptsProgramming} times={times} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} contents={contents} questionsToSave={questionsToSave} updateQuestionsToSave={updateQuestionsToSave}/>
            <div className="wrapper-for-current-viewer">
                {
                    viewerToReturn()
                }
            </div>
            {
                currentViewer === TypesOfLocationEnum.CUSTOM_EXAM && (
                    <Questions open={open} updateOpen={updateOpen} flags={flags} updateFlags={updateFlags} questions={questions} questionsToSave={questionsToSave} contentsNeedToBeSaved={contentsNeedToBeSaved}/>
                )
            }            
            <LocationsForExam errorExists={error} pdfExists={pdfExists} updateCurrentViewer={updateCurrentViewer} currentViewer={currentViewer}/>
        </>
    );
}

export default TakeEvaluation;