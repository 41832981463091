import React, { useEffect } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams } from "react-router-dom";
import SubmitCustomExamForm from "./submitCustomExamForm";
import { useNavigate } from "react-router-dom";
import RunNewTestsProgramming from "./runNewTestsProgramming";

const TopRightCustomExam = ({ submitMutex, text, updateIsAttemptingToSubmit, isAttemptingToSubmit, updateFlip, flip, tryingToReload, saveAnswersCritical, updateTryingToReload, zipFileExists, status, lastStartTime, updateComponentLastStartTime, updateTestsConfigDict, updateRequestLifeCycleFour, updateError, flags, updateStatus, updateAttempt, coupledProgrammingQuestions, allowedAttemptsProgramming, attempt, autoSubmit, contentsNeedToBeSaved, updateContentsNeedToBeSaved, contents, updateQuestionsToSave, questionsToSave }) => {
    const navigate = useNavigate();
    const width = useWindowWidth(700);    
    const widthTwo = useWindowWidth(435);
    const { courseCode, assessmentCode } = useParams();    

    const thereExistsSavedChanges = questionsToSave && (Object.keys(questionsToSave).length > 0 || contentsNeedToBeSaved?.save);

    useEffect(() => {        
        const timer = setTimeout(() => {
            try {
                saveAnswersCritical();
            } catch(error) {
                console.log(error);
            }
            
        }, 5000);

        return () => {
            clearTimeout(timer);
        }

    }, [questionsToSave, contentsNeedToBeSaved])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (thereExistsSavedChanges) {
                // Optionally, you can show a confirmation message if needed
                const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                updateTryingToReload(true);
                return confirmationMessage;
            }            
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [questionsToSave, contentsNeedToBeSaved?.save]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (thereExistsSavedChanges) {
                // Optionally, you can show a confirmation message if needed
                const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
                e.returnValue = confirmationMessage;
                updateTryingToReload(true);
                return confirmationMessage;
            }            
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [questionsToSave, contentsNeedToBeSaved?.save]);

    useEffect(() => {
        const handleBackButton = (event) => {
            if (thereExistsSavedChanges) {
                const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
                if (!confirmLeave) {
                    updateTryingToReload(true);
                    window.history.pushState(null, "", window.location.href);
                } else {
                    navigate(`/${courseCode}/${assessmentCode}`)
                }
            }
        };

        window.addEventListener("popstate", handleBackButton);        

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [questionsToSave, contentsNeedToBeSaved?.save]);

    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
    }, [])

    return (
        <>
            <div className="top-right-custom-wrapper">
                {
                    !widthTwo && !thereExistsSavedChanges && (
                        <div className="auto-save">
                            <svg style={{ marginTop: "2px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                            </svg>
                            Saved
                        </div>
                    )
                }                              
                {
                    zipFileExists && allowedAttemptsProgramming !== null && allowedAttemptsProgramming !== undefined && allowedAttemptsProgramming !== 0 && (
                        <RunNewTestsProgramming updateFlip={updateFlip} flip={flip} status={status} lastStartTime={lastStartTime} updateComponentLastStartTime={updateComponentLastStartTime} updateTestsConfigDict={updateTestsConfigDict} updateRequestLifeCycleFour={updateRequestLifeCycleFour} updateError={updateError} saveAnswersCritical={saveAnswersCritical} updateStatus={updateStatus} width={width} topRight={true} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} attempt={attempt ? attempt : 0} allowedAttemptsProgramming={allowedAttemptsProgramming ? allowedAttemptsProgramming : 0}/>
                    )
                } 
                {
                    tryingToReload && thereExistsSavedChanges && (
                        <div className="top-right-item" onClick={async () => {
                            try {
                                await saveAnswersCritical();
                                updateTryingToReload(false);
                            } catch(error) {
                                console.log(error);
                            }

                        }} style={{ width: width ? "40px" : "" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                            </svg>
                            { !width ? text : "" }
                        </div>
                    )
                }               
                <div className="top-right-item" onClick={() => {                    
                    if (Object.keys(flags).length > 0) {
                        // eslint-disable-next-line no-restricted-globals
                        const confirmed = confirm("You have flagged questions. Are you sure you want to submit?");

                        if (confirmed) {
                            updateIsAttemptingToSubmit(true);
                        }
                    } else {
                        // if there are no flagged questions
                        updateIsAttemptingToSubmit(true);
                    }
                }} style={{ width: width ? "40px" : "" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z"/>
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                    </svg>
                    { width ? "" : "Submit"}
                </div>
            </div>            
            { isAttemptingToSubmit || autoSubmit ? <SubmitCustomExamForm submitMutex={submitMutex} saveAnswersCritical={saveAnswersCritical} autoSubmit={autoSubmit} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} updateQuestionsToSave={updateQuestionsToSave} contents={contents} contentsNeedToBeSaved={contentsNeedToBeSaved} questionsToSave={questionsToSave} updateIsAttemptingToSubmit={updateIsAttemptingToSubmit}/> : <></> }
        </>
    );
}

export default TopRightCustomExam;