import React from "react";
import BubbleWithTextView from "./bubbleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import BubbleWithTextViewGradingPage from "./bubbleWithTextViewGradingPage"
import Explanation from "./explanation";
import FlagUpdate from "./flagUpdate";

const TrueFalseQuestionView = ({ updateQuestions, flags, updateFlags, isDarkTheme, useExplanation, explanation, questionNumber, question, points, grading, updateQuestionsToSave, uuid, bubbledPassed, studentAnswer, bubbledAnswer, pointsEarned }) => {    
    const bubbled = grading ? bubbledPassed : studentAnswer

    const updateNewBubbled = (newValue) => {
        if (!grading) {
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: {
                    bubbled: newValue
                }
            }))
    
            updateQuestions((prev) => {
                return prev.map((question) => {
                    if (question.uuid !== uuid) return question;
    
                    return {
                        ...question,
                        studentAnswer: newValue
                    }
                })
            });
        }        
    }

    function updateBubbled(newBubbled) {
        if (newBubbled === bubbled) {
            updateNewBubbled(-1);
        } else {
            updateNewBubbled(newBubbled);
        }
    }


    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === bubbledAnswer && (bubbledAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== bubbledAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if answered wrong and correct
        else if (studentAnswer !== bubbledAnswer && (bubbledAnswer === answerState)) {
            return 2;
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        <FlagUpdate flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>                        
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="true-false-answer-block">
                    {
                        grading

                        ?

                        <>
                            <BubbleWithTextViewGradingPage text={"True"} state={handleStateCalculation(1)}/>
                            <BubbleWithTextViewGradingPage text={"False"} state={handleStateCalculation(0)}/>
                        </>

                        :

                        <>
                            <BubbleWithTextView text={"True"} bubbled={bubbled === 1} updateBubbled={updateBubbled}/>
                            <BubbleWithTextView text={"False"} bubbled={bubbled === 0} updateBubbled={updateBubbled}/>
                        </>

                    }
                </div>
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default TrueFalseQuestionView;