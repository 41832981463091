import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Explanation from "./explanation";
import Loading from "./loading";
import ASingleTestGrading from "./aSingleTestGrading";
import FullScreenToggler from "./fullScreenToggler";
import FlagUpdate from "./flagUpdate";
import RunNewTestsProgrammingView from "./runNewTestsProgrammingView";

const CodeResponseView = ({ flip, updateQuestions, lastStartTime, requestLifeCycleFour, flags, updateFlags, status, allowedAttemptsProgramming, attempt, endedExam, tests, error, testsConfigDict, requestLifeCycleTwo, explanation, useExplanation, useSyntaxHighlighting, updateQuestionsToSave, updateContents, location, contents, useAutocompletion, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, studentAnswer, pointsEarned }) => {    
    const [fullscreen, setFullscreen] = useState(false);

    const handleChange = (value) => {
        if (!grading) {
            updateContents((prev) => ({
                ...prev,
                [location]: {
                    content: value,
                    isDir: false
                }
            }), location);
        }        
    }

    const handleAnswer = (value) => {
        if (!grading) {
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: {
                    answer: value
                }
            }));
            
            updateQuestions((prev) => {
                return prev.map((question) => {
                    if (question.uuid !== uuid) return question;
    
                    return {
                        ...question,
                        studentAnswer: value
                    }
                })
            }); 
        }           
    }

    const determineIfWeHaveATestToShow = () => {
        const dictLength = testsConfigDict && Object.keys(testsConfigDict).length > 0;

        if (tests && tests.length > 0 && dictLength) {
            for (const key of tests) {
                if (key in testsConfigDict) {
                    return true;
                }
            }
        } else if (error && typeof error === "string") {
            return true;
        }

        return false;
    }

    function updateFullscreen(fullscreen) {
        setFullscreen(fullscreen);
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        <FlagUpdate flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) +" / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="code-response-area-wrapper">
                    {
                        requestLifeCycleTwo ?

                        <>
                            <div className="loading-zip-container-2">
                                <Loading />
                            </div>
                        </>

                        :

                        <>
                            <div className={`code-response-area ${fullscreen ? (grading ? "fullscreen-editor-side-bar" : "fullscreen-editor") : ""}`} style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                                <div className="code-editor-header" style={{ justifyContent: "space-between" }}>
                                    <div className="location">
                                        {
                                            contents && location in contents ? location.split("/").pop() : <> Programming ({language}) </>

                                        }
                                    </div>
                                    <div className="code-editor-header" style={{ width: "fit-content" }}>
                                        <FullScreenToggler updateFullscreen={updateFullscreen} fullscreen={fullscreen}/>
                                        <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                                    </div>
                                </div>
                                <Editor
                                    language={grading ? language : (useSyntaxHighlighting ? language : "plaintext")}
                                    value={contents && location in contents ? contents[location].content : studentAnswer}
                                    theme={ isDarkTheme ? "vs-dark" : "vs"}
                                    onChange={contents && location in contents ? (value) => handleChange(value) : handleAnswer}
                                    options={{
                                        fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                        fontSize: 14, // Default font size (14px)
                                        tabSize: 8,         // Set tab length to 4 spaces
                                        fontWeight: "500", // Default font weight
                                        suggestOnTriggerCharacters: useAutocompletion,
                                        quickSuggestions: useAutocompletion,
                                        readOnly: grading,
                                        contextmenu: false, // Disable right-click context menu
                                        parameterHints: useAutocompletion, // Disable parameter hints for function calls
                                        autoClosingBrackets: true, // Disable automatic closing brackets
                                    }}
                                />
                                <div className="code-editor-header" />
                            </div>
                        </>
                        
                    }
                </div>
                {
                    grading && endedExam && determineIfWeHaveATestToShow() && (
                        // if there is an error or a test to show, show either based on error
                        <div className="true-false-top lambda-results-container">
                            {                                
                                
                                error ? 
                                
                                <div className="stop-overflow-compilation" style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                    See error by navigating to error page
                                </div>

                                :

                                tests.map((id) => {
                                    if (id in testsConfigDict) {
                                        return <ASingleTestGrading greyedOut={status === "RUNNING"} key={id} {...testsConfigDict[id]}/>
                                    }
                                })
                            }
                        </div>
                    )
                }
                {
                    !grading && allowedAttemptsProgramming !== null && allowedAttemptsProgramming !== undefined && allowedAttemptsProgramming !== 0 && tests && Object.keys(tests).length > 0 && (error || attempt > 0) && (
                        <div className="select-all-that-apply-array" style={{ marginTop: "10px" }}>  
                            <RunNewTestsProgrammingView flip={flip} lastStartTime={lastStartTime} requestLifeCycleFour={requestLifeCycleFour} error={error} status={status} attempt={attempt} allowedAttemptsProgramming={allowedAttemptsProgramming ? allowedAttemptsProgramming : 0} tests={tests} testsConfigDict={testsConfigDict}/>
                        </div>
                    )
                }
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default CodeResponseView;