import React, { useEffect, useRef } from "react";
import { MathJaxContext } from "better-react-mathjax";
import Loading from "./loading";
import ViewStatusEvaluation from "./viewStatusEvaluation";
import CustomExamWrapperView from "./customExamWrapperView";
import mathJaxConfig from "./mathJaxConfig";

const CustomExamExtendedWrapperView = ({ saveAnswersCritical, scrollAmount, updateScrollAmount, flip, updateQuestions, lastStartTime, requestLifeCycleFour, flags, updateFlags, contentsNeedToBeSaved, updateContentsNeedToBeSaved, status, allowedAttemptsProgramming, attempt, error, testsConfigDict, questionsToSave, requestLifeCycleTwo, isDarkTheme, updateIsDarkTheme, useSyntaxHighlighting, updateContents, requestLifeCycle, questions, updateQuestionsToSave, contents }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {            
            ref.current.scrollTo({ top: scrollAmount, behavior: "instant" });
        }

    }, [])
    
    return (
        <>
            {
                requestLifeCycle

                ?

                    <> 
                        <div className="total">
                            <Loading />
                        </div>
                    </>

                :

                <>
                    {
                        questions && questions.length > 0

                        ?

                        <>
                            <div ref={ref} className="custom-programming-exam-wrapper" style={{ position: "relative" }} onScroll={(e) => {
                                updateScrollAmount(e.target.scrollTop);                            
                            }}>
                                <MathJaxContext config={mathJaxConfig}>
                                    <CustomExamWrapperView saveAnswersCritical={saveAnswersCritical} flip={flip} updateQuestions={updateQuestions} lastStartTime={lastStartTime} requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} requestLifeCycleTwo={requestLifeCycleTwo} requestLifeCycle={requestLifeCycle} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} updateQuestionsToSave={updateQuestionsToSave} contents={contents} questions={questions} grading={false}/>
                                </MathJaxContext>
                            </div>
                        </>

                        :

                        <>
                            <div className="total">
                                <ViewStatusEvaluation text={"No questions found for this evaluation."}/>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}
export default CustomExamExtendedWrapperView;