import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import Loading from "./loading";
import Info from "./info";
import { useParams, useNavigate } from "react-router-dom";
import EvaluationStatusEnum from "./evaluationStatusEnum";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const EnterEntryCodeForm = ({ updateStatus, beginOrNew }) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Enter your case-senstive entry code")
    const [code, setCode] = useState("");
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();

    function updateCode(code) {
        setCode(code)
    }

    function resetForm() {
        updateStatus(beginOrNew === "first" ? EvaluationStatusEnum.BEGIN : EvaluationStatusEnum.NEW_ATTEMPT)
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }        

        if (code.length === 4 && code) {
            const url = process.env.REACT_APP_EVALUATION_API_URL  + `/begin-${beginOrNew}-attempt`
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    entryCode: code
                })
            }

            try {
                setRequestLifeCycle(true);
                const response = await fetch(url, urlOptions);
                const data = await response.json();

                if (response.status === 200) {
                    setText("Success")
                    localStorage.setItem(assessmentCode, "true");
                    navigate(`/${courseCode}/${assessmentCode}/take-evaluation`);
                } else if (response.status === 450) {
                    // already begun first attempt -> probably an error -> navigate to take page
                    updateStatus(EvaluationStatusEnum.ERROR)
                } else if (response.status === 451) {
                    updateStatus(EvaluationStatusEnum.OUTSIDE_TIME_RANGE);
                } else if (response.status === 452) {
                    setText("Out of attempts")
                } else if (response.status === 453) {
                    setText("Entry codes do not match");
                } else if (response.status === 401) {
                    navigateToLoginPageEnv();
                } else if (response.status === 404) {
                    setText("Resourses not found");
                } else if (response.status === 455) {
                    setText("Please wait one minute to reenter code");
                } else if (response.status === 460) {
                    alert("At least one autograder is running. Wait 15 minutes or for autograding to finish.");
                } else {
                    setText("Status Code: " + response.status + " Error: " + data.detail)
                }
        
            } catch (error) {
                setText("Error: " + error.message)
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
            
        } else {
            setText("Incorrect entry code")
        }
    }

    useEffect(() => {
        setText("Enter your case-senstive entry code")
    }, [code])

    return (
        <>
            <div className="wrapper-for-entry" onClick={resetForm} />
            <div className="enter-entry-code-container" style={{ border: "1px solid var(--gray-five)", borderRadius: "5px", boxShadow: "0px 4px 10px rgba(0,0,0,.5)" }}>
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Enter Entry Code
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                <div className="required">
                                    *
                                </div> Required Field
                            </div>   
                            <div className="banner">
                            {
                                    requestLifeCycle

                                    ?

                                    <>
                                        <Loading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>
                            }
                            </div>
                            <CourseInput top={"Code"} value={code} updateValue={updateCode} required={true} autoComplete={"one-time-code"} placeholder={"Instructor-Provided Code"}/>   
                            <SaveChanges left="Cancel" right={{ small : "Validate", large : "Validate" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>                
        </>
    );
}

export default EnterEntryCodeForm;