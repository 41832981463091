import React, { useEffect, useState } from "react";
import SaveChanges from "./saveChanges";
import { useParams, useNavigate } from "react-router-dom";
import Info from "./info";
import Loading from "./loading";
import redirectArray from "./redirectArray";

const SubmitCustomExamForm = ({ submitMutex, autoSubmit, saveAnswersCritical, updateIsAttemptingToSubmit }) => {
    const [text, setText] = useState("Are you sure you want submit this evaluation (will autosave)?");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();

    const resetForm = () => {
        updateIsAttemptingToSubmit(false);
    }

    const submitForm = async (event) => {
        const release = await submitMutex.acquire();
        
        try {
            if (event && typeof event.preventDefault === 'function') {
                event.preventDefault();
            }

            if (requestLifeCycle) {
                return;
            }

            setRequestLifeCycle(true);

            try {
                await saveAnswersCritical();
            } catch(error) {
                console.log(error);
                setText("Error while saving answers: " + error.message);
                return;
            }  

            const url = process.env.REACT_APP_START_AUTOGRADER_API_URL
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: "placeholder"
                })
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();

            if (response.status === 200) {
                navigate(`/${courseCode}/${assessmentCode}`);

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL                
            } else if (response.status in redirectArray) {
                setText("Failed");
                navigate(`/${courseCode}/${assessmentCode}`);
            } else if (response.status === 400) {
                setText("Autograder failed. Wait 3s for redirection");
                setTimeout(() => {
                    navigate(`/${courseCode}/${assessmentCode}`)
                }, 3000)
            } else {
                setText("Status Code: " + response.status + " Error: " + data.detail);
            }
        } catch (error) {
            setText("Error while submitting: " + error.message);
            console.log(error)
        } finally {
            setRequestLifeCycle(false);
            release();
        }
    }

    useEffect(() => {
        if (autoSubmit) {
            submitForm();
        }
    }, [autoSubmit])

    return (
        <>
            <div className="black-back" onClick={ () => {updateIsAttemptingToSubmit(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Submit Evaluation
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <Loading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Submit", large : "Submit" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default SubmitCustomExamForm;