async function uploadFile(file, url, fileType) {
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': fileType,
            },
            body: file,
        });
        let data = {
            detail: "<No message>"
        }

        try {
            data = await response.json();
        } catch (error) {
            console.log(error)
        }
        
        return [response.status, ("Status Code: " + response.status + " Error: " + data.detail)]
    } catch (error) {
        console.error("Error uploading file:", error);
        return [400, ("Error: " + error.message)];
    }
}

export default uploadFile;