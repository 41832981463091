import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Explanation from "./explanation";
import FlagUpdate from "./flagUpdate";
import AiFeedback from "./aiFeedback";

const TextResponseView = ({ updateQuestions, autoAssign, scoreStatus, status, useAi, results, flags, updateFlags, isDarkTheme, explanation, useExplanation, questionNumber, question, points, grading, updateQuestionsToSave, uuid, studentAnswer, pointsEarned }) => {
    const updateAnswer = (value) => {
        if (!grading && value?.length <= 2000) {
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: {
                    answer: value
                }
            }));
    
            updateQuestions((prev) => {
                return prev.map((question) => {
                    if (question.uuid !== uuid) return question;
    
                    return {
                        ...question,
                        studentAnswer: value
                    }
                })
            });
        }        
    }

    const findScore = () => {
        try {
            return Number(results.score)
        } catch (error) {
            console.log(error)

            return "0"
        }
    }

    const findFeedback = () => {
        try {
            return results.feedback
        } catch (error) {
            console.log(error)

            return ""
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        <FlagUpdate flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">                        
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" value={studentAnswer} onChange={(e) => {updateAnswer(e.target.value)}} style={{ minHeight: grading ? "10px" : "" }} disabled={grading}/>
                </div>
                {
                    grading && results && useAi && (
                        <>
                            {
                                typeof results === "object" ?

                                
                                <div className="select-all-that-apply-array">
                                    <AiFeedback autoAssign={autoAssign} scoreStatus={scoreStatus} name={"AI Feedback"} greyedOut={status === "RUNNING"} pointsEarned={findScore()} output={findFeedback()} totalPoints={points}/>         
                                </div>

                                :

                                <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "", width: "100%", padding: "5px", fontSize: "var(--font-size-one)" }}>
                                    {results}
                                </div>

                            }
                        </>
                    )
                }
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default TextResponseView;