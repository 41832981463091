import React from "react";

const ShowError = ({ error }) => {
    return (
        <>
            <div className="error-wrapper-container">
                <div className="error-wrapper">
                    {error}
                </div>
            </div>
        </>
    );
}

export default ShowError;