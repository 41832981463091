import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import NavInfoAssessment from "./navInfoAssessment";
import useWindowWidth from "./useWindowWidth";
import LeftInfoAssessmentPage from "./leftInfoAssessmentPage";
import PdfAssessmentPageContainerWrapper from "./pdfAssessmentPageContainerWrapper";
import LocationSettingsHover from "./locationSettingsHover";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import CustomExamViewGradedWrapper from "./customExamViewGradedWrapper";
import parseZipFile from "./parseZipFile";
import parseFileStructure from "./parseFileStructure";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";
import findUuidsOfScanAndGrade from "./findUuidsOfScanAndGrade";
import fetchSubmissionPdf from "./fetchSubmissionPdf";
import findStillProcessing from "./findStillProcessing";
import ShowError from "./showError";
import gradeLambda from "./gradeLambda";
import gradeProgramming from "./gradeProgramming";

const AssessmentPage = () => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    useScrollToTop();
    const width = useWindowWidth(1000);
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.SETTINGS)
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();    
    const [contentsForCustomExam, setContentsForCustomExam] = useState({});    
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [gradedQuestions, setGradedQuestions] = useState([]);
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [pdfUrls, setPdfUrls] = useState({})
    const [requestLifeCycleThree, setRequestLifeCycleThree] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [stillProcessing, setStillProcessing] = useState(false);
    const [lastStartTime, setLastStartTime] = useState(null);
    const [mostRecentLastStartTime, setMostRecentLastStartTime] = useState(null);
    const [flip, setFlip] = useState(null);
    const [scrollAmount, setScrollAmount] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();    

    function updateScrollAmount(newAmount) {
        setScrollAmount(newAmount);
    }

    function updateAssessmentInformation(assessmentInformation) {
        setAssessmentInformation(assessmentInformation);
    }

    function updateGradedQuestionsForProgrammingFunc(newData) {
        setGradedQuestions(newData);
    }

    const fetchTests = async (loud = false) => {
        try {
            if (loud) {
                setRequestLifeCycleThree(true);
            }
            const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-tests-student-after/${courseCode}/${assessmentCode}?no_questions=${!loud}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await response.json();
            if (response.status === 200) {
                if (loud || data.status !== 'RUNNING') {
                    if (data.OK) {
                        const testsConfigArray = JSON.parse(data.testsConfig);
                        const testsConfigToStore = testsConfigArray.tests.reduce((acc, curr) => {
                            acc[curr.id] = curr;
                            return acc;
                        }, {});
                        setTestsConfigDict(testsConfigToStore);
                        setError(null);                        
                        if (!loud) {
                            gradeProgramming(gradedQuestions, testsConfigToStore, updateGradedQuestionsForProgrammingFunc);
                        }
                    } else {
                        setError(data.error);
                        setTestsConfigDict({});
                    }
                } 

                setStatus(data.status);
                setLastStartTime(data.lastStartTime);
            } else if (response.status === 401) {
                navigateToLoginPageEnv();
            } else {
                setError(data.detail);
                setTestsConfigDict({});
            }
        } catch (error) {
            setError(error.message);
            setTestsConfigDict({});
            console.log(error);
        } finally {
            if (loud) {
                setRequestLifeCycleThree(false)
            }            
        }
    };

    useEffect(() => {        
        fetchTests(true);
    }, [])

    useEffect(() => {
        const iterateAndFetchPdfs = async () => {
            // initally set requestLifeCycle
            setPdfUrls({
                requestLifeCycle: true
            })

            // returns a dict where key is uuid of scan and grade and value is null
            const questionsDict = findUuidsOfScanAndGrade(assessmentInformation.submission.gradedQuestions);

            // handle the requests for the pdfs
            const dataPromises = Object.keys(questionsDict).map(async uuid => {
                try {
                    const [isOkay, pdfUrl] = await fetchSubmissionPdf(process.env.REACT_APP_SUBMISSION_API_URL + `/get-question-pdf-student/${courseCode}/${assessmentCode}/${uuid}`);
                    if (isOkay) {
                        questionsDict[uuid] = pdfUrl;
                    }
                } catch (error) {
                    console.error(error);
                    return null; // Handle errors gracefully
                }
            });

            await Promise.all(dataPromises);
            questionsDict.requestLifeCycle = false 
            setPdfUrls(questionsDict);
        }

        if (assessmentInformation?.submission?.gradedQuestions && assessmentInformation?.submission?.gradedQuestions?.length > 0) {
            iterateAndFetchPdfs()
        }

        return () => {
            Object.keys(pdfUrls).map((uuid) => {
                if (uuid && pdfUrls[uuid]) {
                    URL.revokeObjectURL(pdfUrls[uuid])
                }
            })
        }

    }, [assessmentInformation?.submission?.gradedQuestions])

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    navigateToLoginPageEnv();
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);
        submitForm(theme);
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);            
            if (response.status === 401) {
                navigateToLoginPageEnv();
            }

        } catch(error) {
            console.log(error);
        }
    }

    const fetchAssessmentInformation = async () => {
        try {
            setRequestLifeCycle(true);
            const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-settings-student/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await res.json();
            if (res.status === 200) {
                setAssessmentInformation(data);
                updateAssessmentInformation(data);                                    
                setGradedQuestions('submission' in data ? data.submission.gradedQuestions : []);                    
            }  else if (res.status === 401) {
                navigateToLoginPageEnv();
            }
    
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    function updateGradedQuestions(newData, uuid) {
        setGradedQuestions((prev) => {
            const newArray = prev.map((question) => {
                if (question.uuid !== uuid) return {...question};

                if (newData.status === 'COMPLETED') {
                    return {
                        ...question,
                        status: newData.status,
                        attempt: newData.attempt,
                        lastStartTime: newData.lastStartTime,
                        results: newData.results,
                        pointsEarned: gradeLambda(question, newData.results, question.questionType)
                    }
                } else if (newData.status === 'FETCH_ERROR') {
                    return {
                        ...question,
                        status: newData.status,
                        results: newData.results
                    }
                }
                
                return {
                    ...question,
                    lastStartTime: newData.lastStartTime,
                }
            })

            return newArray;
        })
    }

    const findQuestion = async (uuid) => {
        try {
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/fetch-lambda-results-student-after/${courseCode}/${assessmentCode}/${uuid}?no_questions=${true}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                updateGradedQuestions(data, uuid);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                updateGradedQuestions({
                    status: "FETCH_ERROR",
                    results: data.detail
                }, uuid);
            }

        } catch (error) {
            console.log(error);
            updateGradedQuestions({
                status: "FETCH_ERROR",
                results: error.message
            }, uuid);
        }
    }

    useEffect(() => {
        fetchAssessmentInformation();
    }, [])

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleTwo(true)
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-submission-zip-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const parsedContents = parseFileStructure(await parseZipFile(await res.blob()))
                    setContentsForCustomExam(parsedContents)
                } else if (res.status === 401) { 
                    navigateToLoginPageEnv();
                }
            } catch (error) { 
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };
        
        fetchZip(); 

    }, [])    

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.PDF: {
                return <PdfAssessmentPageContainerWrapper assessmentInformation={assessmentInformation}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomExamViewGradedWrapper scrollAmount={scrollAmount} updateScrollAmount={updateScrollAmount} status={status} endedExam={assessmentInformation?.submission?.endedExam} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo || requestLifeCycleThree} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} requestLifeCycle={requestLifeCycle} gradedQuestions={gradedQuestions} contents={contentsForCustomExam}/>
            }
            case TypesOfLocationEnum.ERROR: {
                return <ShowError error={error}/>
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        if (currentViewer in TypesOfLocationEnum) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("tab", currentViewer)

            navigate(`?${searchParams}`);
        }
    }, [currentViewer])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get("tab")

        if (tab) {
            setCurrentViewer(tab);
        }

    }, [location.search])

    useEffect(() => {
        // get initial run state    
        const [stillOngoing, currentMostRecentLastStartTime] = findStillProcessing(gradedQuestions, status, lastStartTime)
        setStillProcessing(stillOngoing.questions || stillOngoing.tests);
        setMostRecentLastStartTime(currentMostRecentLastStartTime);
        
        // start an interval and see if 
        const timer = setInterval(async () => {
            const fifteenMinutes = 15 * 60 * 1000;
            const hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(currentMostRecentLastStartTime).getTime())) >= fifteenMinutes);

            if (!hasBeenFifteenMinutes) {
                if (stillOngoing.questions && stillOngoing.tests) {
                    for (let i = 0; i < stillOngoing.uuids.length; i++) {
                        findQuestion(stillOngoing.uuids[i]);
                    }
                    fetchTests();
                } else if (stillOngoing.questions) {
                    for (let i = 0; i < stillOngoing.uuids.length; i++) {
                        findQuestion(stillOngoing.uuids[i]);
                    }
                } else if (stillOngoing.tests) {
                    fetchTests();
                }
            }

            setFlip(prev => !prev);

        }, 5000)

        setFlip(prev => !prev);

        return () => {
            clearInterval(timer);
        }

    }, [status, gradedQuestions, lastStartTime])

    return (
        <>
            <Navigation boolTwo={true} bool={false} info={ <NavInfoAssessment name={assessmentInformation.name}/> }/>
            <div className="courses-container" style={{ position: width ? "relative" : "fixed" }}>
                <div className={`single-course-container viewer full ${width ? "small" : "height"}`}>
                    <div className={`assessment-page-content min ${width ? "small" : ""}`}>
                        {
                            currentViewer === TypesOfLocationEnum.SETTINGS

                            ?

                            <>
                                <LeftInfoAssessmentPage scrollAmount={scrollAmount} updateScrollAmount={updateScrollAmount} flip={flip} mostRecentLastStartTime={mostRecentLastStartTime} status={status} stillProcessing={stillProcessing} endedExam={assessmentInformation?.submission?.endedExam} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo || requestLifeCycleThree} pdfUrls={pdfUrls} gradedQuestions={gradedQuestions} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} contents={contentsForCustomExam} requestLifeCycle={requestLifeCycle} assessmentInformation={assessmentInformation}/>
                            </>

                            :

                            <>
                            </>
                        }
                        {
                            currentViewerToReturn()
                        }
                    </div>
                </div>
            </div>
            {
                assessmentInformation.published

                ?
                
                <LocationSettingsHover errorExists={error} pdfExists={'submission' in assessmentInformation ? assessmentInformation.submission.pdfExists : false} updateCurrentViewer={updateCurrentViewer}/>

                :

                <>
                </>
            }
        </>
    );
}

export default AssessmentPage;