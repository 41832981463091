import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";
import FlagUpdate from "./flagUpdate";

const ContextBlockView = ({ flags, updateFlags, isDarkTheme, questionNumber, question, points, uuid, grading }) => {
    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        C{ questionNumber }
                        <FlagUpdate context={true} flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">
                        { Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
            </div>
        </>
    );
}

export default ContextBlockView;