import React from "react";
import SquareWithTextView from "./squareWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import SquareWithTextViewGradingPage from "./squareWithTextViewGradingPage";
import Explanation from "./explanation";
import FlagUpdate from "./flagUpdate";

const SelectAllThatApplyView = ({ updateQuestions, flags, updateFlags, isDarkTheme, explanation, useExplanation, questionNumber, question, points, options, grading, updateQuestionsToSave, uuid, answerArrayPassed, pointsEarned, studentAnswer, answerArrayAnswer }) => {    
    const answerArray = grading ? answerArrayPassed : studentAnswer;
    
    const updateAnswerArray = (newArray) => {
        if (!grading) {
            // updateQuestionsToSave
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: {
                    answerArray: newArray
                }
            }))

            updateQuestions((prev) => {
                return prev.map((question) => {
                    if (question.uuid !== uuid) return question;

                    return {
                        ...question,
                        studentAnswer: newArray
                    }
                })
            });
        }
    }

    function updateIndex(newIndex) {
        if (answerArray.includes(newIndex)) {
            updateAnswerArray(answerArray.filter(index => index !== newIndex)); // Remove newIndex
        } else {
            updateAnswerArray([...answerArray, newIndex]); // Add newIndex
        }
    }

    const studentAnswerIsAnOptionAndIsInAnswerArray = (arrayOfAnswers, arrayOfStudentAnswers, answerToLookFor) => {
        if (!arrayOfStudentAnswers.includes(answerToLookFor)) {
            return false;
        } else if (arrayOfAnswers.includes(answerToLookFor)) {
            return true;
        }
    }

    const handleStateCalculation = (answerState) => {
        const studentAnswerIsInAnswerArray = studentAnswerIsAnOptionAndIsInAnswerArray(answerArrayAnswer, studentAnswer, answerState)
        // check if answers match or check if they match the possible answerState
        if (studentAnswerIsInAnswerArray && (answerArrayAnswer.includes(answerState))) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (!studentAnswerIsInAnswerArray && (studentAnswer.includes(answerState))) {
            return 0;
        }
        // if answer but student not correct
        else if (!studentAnswerIsInAnswerArray && (answerArrayAnswer.includes(answerState))) {
            return 2
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        <FlagUpdate flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="select-all-that-apply-array">
                    {
                        options && options.length > 0

                        ?

                        options.map((option) => (
                            grading
                            
                            ?

                            <SquareWithTextViewGradingPage key={option.index} state={handleStateCalculation(option.index)} text={option.option}/>      

                            :
                            
                            <SquareWithTextView key={option.index} correct={answerArray.includes(option.index)} index={option.index} text={option.option} updateIndex={updateIndex}/>
                        ))

                        :

                        <>
                        </>
                    }
                </div>
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default SelectAllThatApplyView;