import React from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const Map = ({ updateCurrentViewerSplitScreen, currentViewerSplitScreen, pdfExists, errorExists }) => {
    return (
        <>
            <select className="map-top-level" value={currentViewerSplitScreen} onChange={(e) => {
                updateCurrentViewerSplitScreen(e.target.value);
            }}>
                {
                    pdfExists && (
                        <option key={TypesOfLocationEnum.PDF} value={TypesOfLocationEnum.PDF}>
                            PDF
                        </option>
                    )
                }
                {
                    errorExists && (
                        <option key={TypesOfLocationEnum.ERROR} value={TypesOfLocationEnum.ERROR}>
                            Error
                        </option>
                    )
                }
                
            </select>
        </>
    );
}

export default Map;