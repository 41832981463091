import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./loading";
import ASingleTestLambdaGrading from "./aSingleTestLambdaGrading";
import Tippy from "@tippyjs/react";
import LoadingSpinner from "./loadingSpinner";
import redirectArray from "./redirectArray";

const RunNewTests = ({ updateQuestions, attempt, lastStartTime, saveAnswersCritical, status, testsMap, results, uuid, language, allowedAttempts }) => {
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [flip, setFlip] = useState(false);
    const navigate = useNavigate();

    const updateQuestionError = (newError) => {
        updateQuestions((prev) => {
            return prev.map((question) => {
                if (question.uuid !== uuid) return question;

                return {
                    ...question,
                    results: newError
                }
            })
        });
    }

    const fifteenMinutes = 15 * 60 * 1000;
    const hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(lastStartTime).getTime())) >= fifteenMinutes);
 
    const handleClick = async () => {
        if (requestLifeCycle || (attempt >= allowedAttempts && allowedAttempts !== -1)) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            await saveAnswersCritical();
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-question-student/${courseCode}/${assessmentCode}/${uuid}/${language}?no_questions=${true}`
            const urlOptions = {
                method: "POST",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                updateQuestions((prev) => {
                    return prev.map((question) => {
                        if (question.uuid !== uuid) return question;

                        return {
                            ...question,
                            status: 'RUNNING',
                            attempt: (question.attempt !== null && question.attempt !== undefined) ? (question.attempt + 1) : 1,
                            lastStartTime: new Date()
                        }
                    })
                });

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else if (response.status in redirectArray) {
                navigate(`/${courseCode}/${assessmentCode}`);
            } else {
                updateQuestionError("Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch (error) {
            updateQuestionError("Error: " + error.message);
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    const findRemaining = () => {
        const result = allowedAttempts - attempt;

        if (result < 0) {
            return 0;
        }

        return result;
    }

    const fetchResults = async () => {
        try {
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/fetch-lambda-results-student/${courseCode}/${assessmentCode}/${uuid}?no_questions=${true}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                if (data.status !== "RUNNING") {
                    updateQuestions((prev) => {
                        return prev.map((question) => {
                            if (question.uuid !== uuid) return question;
    
                            return {
                                ...question,
                                status: data.status,
                                attempt: data.attempt,
                                results: data.results
                            }
                        })
                    });
                }

                updateQuestions((prev) => {
                    return prev.map((question) => {
                        if (question.uuid !== uuid) return question;

                        return {
                            ...question,
                            lastStartTime: data.lastStartTime
                        }
                    })
                });
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                updateQuestionError("Status Code: " + response.status + " Error: " + data.detail)
            }

        } catch (error) {
            updateQuestionError("Error: " + error.message);
            console.log(error);
        }
    }

    useEffect(() => {        
        const interval = setInterval(() => {
            if (status === 'RUNNING' && !hasBeenFifteenMinutes) {
                fetchResults();
                setFlip(prev => !prev);
            }            
            
        }, 1000);
        
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };

    }, [flip, status, lastStartTime]);

    return (
        <>
            {
                requestLifeCycle ?

                <div className="loading-zip-container" style={{ maxHeight: "50px", height: "50px", minHeight: "50px" }}>
                    <Loading />
                </div>

                :

                testsMap && typeof testsMap === "object" && Object.keys(testsMap).length > 0 && (
                    <div className="run-new">
                        <div className="tests-center" style={{ marginBottom: "10px", columnGap: "10px" }}>
                            <div className={`top-right-item run-tests ${attempt >= allowedAttempts && allowedAttempts !== -1 ? "greyed-out-run-tests" : "" }`} onClick={handleClick} style={{ width: "fit-content", paddingLeft: "10px", paddingRight: "10px" }}>
                                Run Tests {allowedAttempts !== -1 ? `(${findRemaining()} left)` : ""}
                                <Tippy content={<div style={{ fontSize: '12px', textAlign: "center" }}>Running the lambda autograder will evaluate only the tests assigned for this question (will autosave).</div>} placement={"top"}>
                                    <div className="docs-wrapper-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 16 16">
                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>
                            </div>
                        </div>
                        {                            
                            attempt > 0 && (
                                <>
                                    <div className="attempt-processing-container">
                                        Attempt {attempt}{allowedAttempts === -1 ? "" : "/" +  allowedAttempts}{status === 'RUNNING' && !hasBeenFifteenMinutes ? " is processing" : ""}: {status === 'RUNNING' && !hasBeenFifteenMinutes ? <LoadingSpinner /> : <></>}
                                    </div>                                    
                                    <div className="true-false-top lambda-results-container">
                                        {
                                            typeof results === "object" && results !== null ? 

                                            Object.keys(results).map((id) => {                            

                                                if (id in testsMap) {
                                                    const test_result = results[id];
                                                    const original_test = testsMap[id];

                                                    return <ASingleTestLambdaGrading passPoints={original_test.passPoints} greyedOut={status === "RUNNING"} key={id} testName={original_test.testName} pointsEarned={original_test[test_result.passed ? "passPoints" : "failPoints"]} output={test_result.output} passed={test_result.passed}/>
                                                }
                                            })

                                            :
                                            
                                            <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                                {results}
                                            </div>
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </>    
    );
}

export default RunNewTests;