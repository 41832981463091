import React from "react";
import useWindowWidth from "./useWindowWidth";
import CustomExamExtendedWrapperView from "./customExamExtendedWrapperView";
import PdfTakeEval from "./pdfTakeEval";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import ShowError from "./showError";

const SplitScreenView = ({ requestLifeCycleThree, saveAnswersCritical, scrollAmount, updateScrollAmount, flip, updateQuestions, errorExists, pdfExists, updateCurrentViewerSplitScreen, updateCurrentViewer, updateOpen, currentViewerSplitScreen, lastStartTime, requestLifeCycleFour, flags, updateFlags, contentsNeedToBeSaved, updateContentsNeedToBeSaved, status, allowedAttemptsProgramming, attempt, error, testsConfigDict, questionsToSave, isDarkTheme, updateIsDarkTheme, useSyntaxHighlighting, updateContents, requestLifeCycle, questions, updateQuestionsToSave, contents, requestLifeCycleTwo, pdf}) => {
    const width = useWindowWidth(1000);

    const findSplitScreenFactoryRet = () => {
        if (currentViewerSplitScreen === TypesOfLocationEnum.PDF) {
            return <PdfTakeEval currentViewer={TypesOfLocationEnum.SPLIT_SCREEN} requestLifeCycle={requestLifeCycleTwo} pdf={pdf}/>
        } else if (currentViewerSplitScreen === TypesOfLocationEnum.ERROR) {
            return <ShowError error={error}/>
        } else if (errorExists) {
            updateCurrentViewerSplitScreen(TypesOfLocationEnum.ERROR);
        } else if (pdfExists) {
            updateCurrentViewerSplitScreen(TypesOfLocationEnum.PDF);
        } else {
            updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
        }
    }

    return (
        <>
            <div className="split-screen-container" style={{ flexDirection: width ? "column" : "row" }}>
                {
                    width 

                    ?

                    <>
                        <div className={`wrapper-for-split-screen-custom-exam ${width ? "new-height" : ""}`}>
                            <CustomExamExtendedWrapperView requestLifeCycleTwo={requestLifeCycleThree} saveAnswersCritical={saveAnswersCritical} scrollAmount={scrollAmount} updateScrollAmount={updateScrollAmount} flip={flip} updateQuestions={updateQuestions} lastStartTime={lastStartTime} requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents}/>
                        </div>
                        <div className="split-screen-wrapper">
                            {findSplitScreenFactoryRet()}
                        </div>
                    </>

                    :

                    <>
                        <div className="split-screen-wrapper">
                            {findSplitScreenFactoryRet()}
                        </div>
                        <div className={`wrapper-for-split-screen-custom-exam ${width ? "new-height" : ""}`}>
                            <CustomExamExtendedWrapperView requestLifeCycleTwo={requestLifeCycleThree} saveAnswersCritical={saveAnswersCritical} scrollAmount={scrollAmount} updateScrollAmount={updateScrollAmount} flip={flip} updateQuestions={updateQuestions} lastStartTime={lastStartTime} requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved}  status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents}/>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default SplitScreenView;