import React from "react";
import CircleWithTextView from "./circleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import CircleWithTextViewGradingPage from "./circleWithTextViewGradingPage";
import Explanation from "./explanation";
import FlagUpdate from "./flagUpdate";

const MultipleChoiceView = ({ updateQuestions, flags, updateFlags, isDarkTheme, explanation, useExplanation, questionNumber, question, points, options, grading, updateQuestionsToSave, uuid, answerIndexPassed, pointsEarned, studentAnswer, answerIndexAnswer }) => {    
    const answerIndex = grading ? answerIndexPassed : studentAnswer

    const updateAnswerIndex = (newIndex) => {
        if (!grading) {
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: {
                    answerIndex: newIndex
                }
            }))
    
            updateQuestions((prev) => {
                return prev.map((question) => {
                    if (question.uuid !== uuid) return question;
    
                    return {
                        ...question,
                        studentAnswer: newIndex
                    }
                })
            });
        }        
    }

    function updateIndex(newIndex) {
        if (newIndex === answerIndex) {
            updateAnswerIndex(-1)
        } else {
            updateAnswerIndex(newIndex);
        }
    }

    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === answerIndexAnswer && (answerIndexAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== answerIndexAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if answer but student incorrect
        else if (studentAnswer !== answerIndexAnswer && (answerIndexAnswer === answerState)) {
            return 2;
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        <FlagUpdate flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="select-all-that-apply-array">
                    {
                        options && options.length > 0 

                        ?

                        options.map((option) => (
                            
                            grading 

                            ?

                            <CircleWithTextViewGradingPage key={option.index} state={handleStateCalculation(option.index)} text={option.option}/>

                            :

                            <CircleWithTextView key={option.index} correct={answerIndex === option.index} index={option.index} text={option.option} updateIndex={updateIndex}/>
                        ))

                        :

                        <>
                        </>

                    }
                </div>
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default MultipleChoiceView;