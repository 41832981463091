import React from "react";
import LocationSettingsItem from "./locationSettingsItem";
import PdfFillDoc from "./pdfFillDoc";
import GradingDoc from "./gradingDoc";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import NewProgrammingExamIcon from "./newProgrammingExamIcon";
import Error from "./error";

const LocationSettingsHover = ({ updateCurrentViewer, pdfExists, errorExists }) => {

    return (
        <>
            <div className="location-settings-hover">
                {
                    errorExists

                    ?

                    <LocationSettingsItem svgOne={<Error />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.ERROR}/>

                    :

                    <>
                    </>
                }
                {
                    pdfExists

                    ?

                    <LocationSettingsItem svgOne={<PdfFillDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.PDF}/>

                    :

                    <>
                    </>
                }
                <LocationSettingsItem svgOne={<GradingDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.SETTINGS}/>                
                <LocationSettingsItem svgOne={<NewProgrammingExamIcon />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.CUSTOM_EXAM}/>
            </div>
        </>
    );
}

export default LocationSettingsHover;