import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const gradeProgramming = (questions, testsConfigDict, updateGradedQuestions) => {
    // iterate over graded questions and update points earned for each programming based on passed test results
    if (!questions || !testsConfigDict || !updateGradedQuestions) return;

    for (let i = 0; i < questions.length; i++) {
        let currentQuestion = questions[i];
        let currentUuid = currentQuestion.uuid;
        let pointsEarned = 0;

        if (currentQuestion.questionType !== TypesOfQuestionsEnum.PROGRAMMING || currentQuestion.useCustomScore) continue;

        for (let j = 0; j < currentQuestion.tests.length; j++) {
            let currentTest = currentQuestion.tests[j];

            if (currentTest in testsConfigDict) {
                let currentPointsEarned = testsConfigDict[currentTest].pointsEarned;
                pointsEarned += (currentPointsEarned !== null && currentPointsEarned !== undefined) ? currentPointsEarned : 0;
            }
        }

        updateGradedQuestions((prev) => {
            return prev.map((question) => {
                if (currentUuid !== question.uuid) return question;

                return {
                    ...question,
                    pointsEarned: pointsEarned
                }
            })
        })

    }
}

export default gradeProgramming;