import React from "react";
import TopRightCustomExam from "./topRightCustom";
import CountdownTimer from "./coutdownTimer";
import useWindowWidth from "./useWindowWidth";
import Map from "./map";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./loading";
import redirectArray from "./redirectArray";

const NavTakeEvaluation = ({ submitMutex, text, updateIsAttemptingToSubmit, isAttemptingToSubmit, updateContentsToNull, updateRequestLifeCycleNav, updateAutoSubmit, updateTryingToReload, autoSubmit, requestLifeCycleNav, tryingToReload, fetchZip, resetZipFile, saveAnswersCritical, updateFlip, flip, updateCurrentViewerSplitScreen, currentViewerSplitScreen, error, pdfExists, currentViewer, zipFileExists, status, lastStartTime, updateComponentLastStartTime, updateTestsConfigDict, updateRequestLifeCycleFour, updateError, flags, updateStatus, updateAttempt, coupledProgrammingQuestions, allowedAttemptsProgramming, attempt, inKioskParent, times, contentsNeedToBeSaved, updateContentsNeedToBeSaved, contents, updateQuestionsToSave, questionsToSave }) => {
    const widthTwo = useWindowWidth(435);
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();

    const findQuestions = () => {
    
        let stringToReturn = "";
    
        for (let i = 0; i < coupledProgrammingQuestions.length; i++) {
            if (i > 0) {
                stringToReturn += ", ";
            }
            stringToReturn += coupledProgrammingQuestions[i];
        }
    
        return stringToReturn;
    }

    const handleClick = async () => {
        try {
            updateRequestLifeCycleNav(true);

            // eslint-disable-next-line no-restricted-globals
            const didConfirm = confirm(`This will erase all of your work for the following questions: ${findQuestions()}. Your answers will return to the original zip file from the evaluation start. Are you sure you want to proceed?`)

            if (!didConfirm) {
                return;
            }

            const url = process.env.REACT_APP_EVALUATION_API_URL + `/reset-zip-file/${courseCode}/${assessmentCode}`
            const urlOptions = {
                method: "POST",
                credentials: "include"
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();
            
            if (response.status === 200) {
                updateContentsToNull();
                updateContentsNeedToBeSaved({
                    save: false
                })
                fetchZip();
            } else if (response.status in redirectArray) {
                navigate(`/${courseCode}/${assessmentCode}`);
            } else {
                alert("Issue in reset zip file request: " + "Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch(error) {
            console.log(error);
            alert("Issue in reset zip file request: " + error.message);
        } finally {
            updateRequestLifeCycleNav(false);
        }
    }
    
    return (
        <>
            <div className="nav-container">
                <div className="nav-sub-take-container">
                    {
                        !widthTwo && (
                            <div style={{ cursor: "pointer" }} onClick={() => {
                                if (questionsToSave && (Object.keys(questionsToSave).length > 0 || contentsNeedToBeSaved?.save)) {
                                    const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
                                    if (!confirmLeave) {
                                        updateTryingToReload(true);
                                        window.history.pushState(null, "", window.location.href);
                                    } else {
                                        navigate("/");
                                    }
                                } else {
                                    navigate("/");
                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                                    <path fill="#0B73FE" stroke="#0B73FE" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                                </svg>
                            </div>
                        )
                    }
                    {
                        currentViewer === TypesOfLocationEnum.SPLIT_SCREEN && error && pdfExists && (
                            <Map currentViewerSplitScreen={currentViewerSplitScreen} errorExists={error} pdfExists={pdfExists} updateCurrentViewerSplitScreen={updateCurrentViewerSplitScreen}/>
                        )
                    }
                    <CountdownTimer times={times} updateAutoSubmit={updateAutoSubmit} autoSubmit={autoSubmit}/>
                    {
                        resetZipFile && zipFileExists && (
                            <>
                                {
                                    requestLifeCycleNav ?

                                    <Loading />

                                    :

                                    <div className="reset-zip" style={{ cursor: "pointer" }} onClick={handleClick}>
                                        Reset zip
                                    </div>

                                }                                
                            </>
                        )
                    }
                </div>                
                <TopRightCustomExam submitMutex={submitMutex} text={text} updateIsAttemptingToSubmit={updateIsAttemptingToSubmit} isAttemptingToSubmit={isAttemptingToSubmit} saveAnswersCritical={saveAnswersCritical} updateFlip={updateFlip} flip={flip} updateTryingToReload={updateTryingToReload} tryingToReload={tryingToReload} zipFileExists={zipFileExists} status={status} lastStartTime={lastStartTime} updateComponentLastStartTime={updateComponentLastStartTime} updateTestsConfigDict={updateTestsConfigDict} updateRequestLifeCycleFour={updateRequestLifeCycleFour} updateError={updateError}  flags={flags} updateStatus={updateStatus} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} autoSubmit={autoSubmit} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} contents={contents} updateQuestionsToSave={updateQuestionsToSave} questionsToSave={questionsToSave} />
            </div>
        </>
    );
}

export default NavTakeEvaluation;