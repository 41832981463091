import React from "react";
import ASingleTestGrading from "./aSingleTestGrading";
import 'tippy.js/dist/tippy.css';
import Loading from "./loading";
import LoadingSpinner from "./loadingSpinner";

const RunNewTestsProgrammingView = ({ flip, lastStartTime, requestLifeCycleFour, error, status, attempt, tests, testsConfigDict, allowedAttemptsProgramming }) => {
    const fifteenMinutes = 15 * 60 * 1000;
    const hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(lastStartTime).getTime())) >= fifteenMinutes);

    return (
        <>              
            {
                requestLifeCycleFour ?

                <>
                    <div className="loading-zip-container" style={{ maxHeight: "150px", height: "150px", minHeight: "50px" }}>
                        <Loading />
                    </div>
                </>

                :

                <>
                    {
                        tests && Array.isArray(tests) && tests.length > 0 && (
                            <div className="run-new">
                                {
        
                                    attempt > 0 && (
                                        <>
                                            <div className="attempt-processing-container">
                                                Attempt {attempt}{allowedAttemptsProgramming === -1 ? "" : "/" +  allowedAttemptsProgramming}{status === 'RUNNING' && !hasBeenFifteenMinutes ? " is processing" : ""}: {status === 'RUNNING' && !hasBeenFifteenMinutes ? <LoadingSpinner /> : <></>}
                                            </div>                                            
                                            <div className="true-false-top lambda-results-container">
                                                {                                                    
                                                    error ? 
    
                                                    <div className="stop-overflow-compilation" style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                                        See error by navigating to error page
                                                    </div>
        
                                                    :
        
                                                    tests.map((id) => {
                                                        if (id in testsConfigDict) {
                                                            return <ASingleTestGrading greyedOut={status === "RUNNING"} key={id} {...testsConfigDict[id]}/>
                                                        }
                                                    })
                                                }                                                
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        )
                    }
                </>                
            }
        </> 
    );
}

export default RunNewTestsProgrammingView;